import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {actions} from './actions'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {actionTypes} from './constants'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'

function* getAllServiceManagerSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getAllServiceManager, params)
    const data: any = response?.data
    yield put(actions.getAllServiceManagerSuccess(data))
  } catch (error: any) {
    yield put(actions.getAllServiceManagerError(error))
  }
}

function* enableServiceManagerSaga(action: ActionModel) {
  try {
    const selectedServices: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableServiceManager, selectedServices)
    yield put(actions.enableServiceManagerSuccess(response?.data))
    yield put(actions.enableServiceManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableServiceManagerFinish())
  }
}

function* disableServiceManagerSaga(action: ActionModel) {
  try {
    const selectedServices: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableServiceManager, selectedServices)
    yield put(actions.disableServiceManagerSuccess(response?.data))
    yield put(actions.disableServiceManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.disableServiceManagerFinish())
  }
}

function* singleEnableServiceManagerSaga(action: ActionModel) {
  try {
    const selectedServices: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableServiceManager, selectedServices)
    yield put(actions.singleEnableServiceManagerSuccess(response?.data))
    yield put(actions.singleEnableServiceManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableServiceManagerFinish())
  }
}

function* singleDisableServiceManagerSaga(action: ActionModel) {
  try {
    const selectedServices: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.singleDisableServiceManager,
      selectedServices
    )
    yield put(actions.singleDisableServiceManagerSuccess(response?.data))
    yield put(actions.singleDisableServiceManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableServiceManagerFinish())
  }
}

function* deleteServiceManagerSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteServiceManager, body)
    yield put(actions.deleteServiceManagerSuccess(response.data?.data))
    yield put(actions.deleteServiceManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteServiceManagerFinish())
  }
}

function* addServiceManagerSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addServiceManager, body)
    yield put(actions.addServiceManagerSuccess(response.data?.data))
    yield put(actions.addServiceManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addServiceManagerFinish())
  }
}

function* updateServiceManagerSaga(action: ActionModel) {
  console.log(action, 'saga')
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(
      service.updateServiceManager,
      body,
      action?.payload?.id
    )
    yield put(actions.updateServiceManagerSuccess(response.data?.data))
    yield put(actions.updateServiceManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateServiceManagerFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_ALL_SERVICE_MANAGER_START, getAllServiceManagerSaga)
  yield takeLatest(actionTypes.ENABLE_SERVICE_MANAGER_REQUEST, enableServiceManagerSaga)
  yield takeLatest(actionTypes.DISABLE_SERVICE_MANAGER_REQUEST, disableServiceManagerSaga)
  yield takeLatest(
    actionTypes.SINGLE_ENABLE_SERVICE_MANAGER_REQUEST,
    singleEnableServiceManagerSaga
  )
  yield takeLatest(
    actionTypes.SINGLE_DISABLE_SERVICE_MANAGER_REQUEST,
    singleDisableServiceManagerSaga
  )
  yield takeLatest(actionTypes.DELETE_SERVICE_MANAGER_START, deleteServiceManagerSaga)
  yield takeLatest(actionTypes.ADD_SERVICE_MANAGER_START, addServiceManagerSaga)
  yield takeLatest(actionTypes.UPDATE_SERVICE_MANAGER_START, updateServiceManagerSaga)
}
