import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {SubServiceManagerModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const SUB_SERVICE_MANAGER_DATA = `${API_URL}/subService`

export const service = {
  getAllSubServiceManager: (params: ParamsModel) => {
    return axios.get(SUB_SERVICE_MANAGER_DATA, {params})
  },

  enableSubServiceManager: (data: Array<string>) => {
    const subServiceManager = {
      subServiceId: data,
    }
    return axios.patch(`${SUB_SERVICE_MANAGER_DATA}/enable`, subServiceManager)
  },

  disableSubServiceManager: (data: Array<string>) => {
    const subServiceManager = {
      subServiceId: data,
    }
    return axios.patch(`${SUB_SERVICE_MANAGER_DATA}/disable`, subServiceManager)
  },

  singleEnableSubServiceManager: (data: Array<string>) => {
    const selectedSubServiceManager = {
      subServiceId: [data],
    }
    return axios.patch(`${SUB_SERVICE_MANAGER_DATA}/enable`, selectedSubServiceManager)
  },

  singleDisableSubServiceManager: (data: Array<string>) => {
    const selectedSubServiceManager = {
      subServiceId: [data],
    }
    return axios.patch(`${SUB_SERVICE_MANAGER_DATA}/disable`, selectedSubServiceManager)
  },

  deleteSubServiceManager: (data: {id: string}) => {
    return axios.delete(SUB_SERVICE_MANAGER_DATA, {data})
  },

  addSubServiceManager: (data: any) => {
    return axios.post(SUB_SERVICE_MANAGER_DATA, data)
  },

  updateSubServiceManager: (data: SubServiceManagerModel, id: string) => {
    console.log(id, 'axios')
    return axios.patch(`${SUB_SERVICE_MANAGER_DATA}/${id}`, data)
  },
}
