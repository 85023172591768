export const actionTypes = {
  GET_ALL_SERVICE_MANAGER_START: 'GET_ALL_SERVICE_MANAGER_START',
  GET_ALL_SERVICE_MANAGER_SUCCESS: 'GET_ALL_SERVICE_MANAGER_SUCCESS',
  GET_ALL_SERVICE_MANAGER_FINISH: 'GET_ALL_SERVICE_MANAGER_FINISH',

  ADD_SERVICE_MANAGER_START: 'ADD_SERVICE_MANAGER_START',
  ADD_SERVICE_MANAGER_SUCCESS: 'ADD_SERVICE_MANAGER_SUCCESS',
  ADD_SERVICE_MANAGER_FINISH: 'ADD_SERVICE_MANAGER_FINISH',
  RESET_SERVICE_MANAGER: 'RESET_SERVICE_MANAGER',

  UPDATE_SERVICE_MANAGER_START: 'UPDATE_SERVICE_MANAGER_START',
  UPDATE_SERVICE_MANAGER_SUCCESS: 'UPDATE_SERVICE_MANAGER_SUCCESS',
  UPDATE_SERVICE_MANAGER_FINISH: 'UPDATE_SERVICE_MANAGER_FINISH',

  DELETE_SERVICE_MANAGER_START: 'DELETE_SERVICE_MANAGER_START',
  DELETE_SERVICE_MANAGER_SUCCESS: 'DELETE_SERVICE_MANAGER_SUCCESS',
  DELETE_SERVICE_MANAGER_FINISH: 'DELETE_SERVICE_MANAGER_FINISH',

  SINGLE_DISABLE_SERVICE_MANAGER_REQUEST: 'SINGLE_DISABLE_SERVICE_MANAGER_REQUEST',
  SINGLE_DISABLE_SERVICE_MANAGER_SUCCESS: 'SINGLE_DISABLE_SERVICE_MANAGER_SUCCESS',
  SINGLE_DISABLE_SERVICE_MANAGER_FINISH: 'SINGLE_DISABLE_SERVICE_MANAGER_FINISH',

  SINGLE_ENABLE_SERVICE_MANAGER_REQUEST: 'SINGLE_ENABLE_SERVICE_MANAGER_REQUEST',
  SINGLE_ENABLE_SERVICE_MANAGER_SUCCESS: 'SINGLE_ENABLE_SERVICE_MANAGER_SUCCESS',
  SINGLE_ENABLE_SERVICE_MANAGER_FINISH: 'SINGLE_ENABLE_SERVICE_MANAGER_FINISH',

  ENABLE_SERVICE_MANAGER_REQUEST: 'ENABLE_SERVICE_MANAGER_REQUEST',
  ENABLE_SERVICE_MANAGER_SUCCESS: 'ENABLE_SERVICE_MANAGER_SUCCESS',
  ENABLE_SERVICE_MANAGER_FINISH: 'ENABLE_SERVICE_MANAGER_FINISH',

  DISABLE_SERVICE_MANAGER_REQUEST: 'DISABLE_SERVICE_MANAGER_REQUEST',
  DISABLE_SERVICE_MANAGER_SUCCESS: 'DISABLE_SERVICE_MANAGER_SUCCESS',
  DISABLE_SERVICE_MANAGER_FINISH: 'DISABLE_SERVICE_MANAGER_FINISH',
}
