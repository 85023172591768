import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {ServiceManagerModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const SERVICE_MANAGER_DATA = `${API_URL}/service`

export const service = {
  getAllServiceManager: (params: ParamsModel) => {
    return axios.get(SERVICE_MANAGER_DATA, {params})
  },

  enableServiceManager: (data: Array<string>) => {
    const serviceManager = {
      serviceId: data,
    }
    return axios.patch(`${SERVICE_MANAGER_DATA}/enable`, serviceManager)
  },

  disableServiceManager: (data: Array<string>) => {
    const serviceManager = {
      serviceId: data,
    }
    return axios.patch(`${SERVICE_MANAGER_DATA}/disable`, serviceManager)
  },

  singleEnableServiceManager: (data: Array<string>) => {
    const selectedServiceManager = {
      serviceId: [data],
    }
    return axios.patch(`${SERVICE_MANAGER_DATA}/enable`, selectedServiceManager)
  },

  singleDisableServiceManager: (data: Array<string>) => {
    const selectedServiceManager = {
      serviceId: [data],
    }
    return axios.patch(`${SERVICE_MANAGER_DATA}/disable`, selectedServiceManager)
  },

  deleteServiceManager: (data: {id: string}) => {
    return axios.delete(SERVICE_MANAGER_DATA, {data})
  },

  addServiceManager: (data: any) => {
    return axios.post(SERVICE_MANAGER_DATA, data)
  },

  updateServiceManager: (data: ServiceManagerModel, id: string) => {
    console.log(id, 'axios')
    return axios.patch(`${SERVICE_MANAGER_DATA}/${id}`, data)
  },
}
