import BackendSettings from '../modules/siteSettings/components/backendSettings/components'
import ContactSetting from '../modules/siteSettings/components/contactSettings/ContactSetting'
import KeySettings from '../modules/siteSettings/components/keySetting/components'
import ModuleManager from '../modules/siteSettings/components/moduleManager/components/ModuleManager'
import Settings from '../modules/siteSettings/components/settings/components'
import SmtpSettings from '../modules/siteSettings/components/smtpSetting/components'
import Departments from '../modules/userMgmt/components/departments/components'
import RoleManagementIndex from '../modules/userMgmt/components/roles/components/RoleMgmt'
import UserRoleManagementIndex from '../modules/userMgmt/components/userRoles/UserRole'
import UserManagementIndex from '../modules/userMgmt/components/users/components/users'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'

import {RouteComponentProps} from 'react-router-dom'
import ContentManager from 'src/app/modules/cms/components/content/components/index'
import MenuManager from '../modules/cms/components/menu/components'

import BranchComponent from '../modules/network/components/branch/component'

import Banner from '../modules/cms/components/banner/components'

import RoadBlockPopup from '../modules/cms/components/roadblockPopup/components'
import TextPopup from '../modules/cms/components/textPopup/components'

import FaqCategory from '../modules/cms/components/faqCategory/components'
import FaqManager from '../modules/cms/components/faqManager/components'

import EmailTemplate from '../modules/cms/components/emailTemplate/components'
import FormDownloadComponent from '../modules/formsAndDownload/components/formsDownload/component'

import HomepageSetting from '../modules/siteSettings/components/homepageSettings/components'

import EnquiryComponent from '../modules/inquiry/components/enquiryManager/component'
import EnquiryTypeComponent from '../modules/inquiry/components/enquiryType/component'

import AccountOpeningDetail from '../modules/common/components/AccountOpening'
import CheckerList from '../modules/onlineAccount/checkerList/components'
import MakerList from '../modules/onlineAccount/makerList/components'
import PendingAccountList from '../modules/onlineAccount/pendingAccount/components'
import PendingAccountDetailList from '../modules/onlineAccount/pendingAccount/components/Details'

import CheckerDetaiList from '../modules/onlineAccount/checkerList/components/Details'
import MakerDetaiList from '../modules/onlineAccount/makerList/components/Details'
import MakerRepairList from '../modules/onlineAccount/makerRepairList/components'
import PreliminaryUnverified from '../modules/onlineAccount/preliminaryUnverified/components'
import PreliminaryUnverifiedDetail from '../modules/onlineAccount/preliminaryUnverified/components/Detail'
import PreliminaryVerified from '../modules/onlineAccount/preliminaryVerified/components'
import PreliminaryVerifiedDetail from '../modules/onlineAccount/preliminaryVerified/components/Detail'
import RejectedList from '../modules/onlineAccount/rejectedList/components'
import AccountOpeningRejectedList from '../modules/onlineAccount/rejectedList/components/Details'

import CheckerRepairDetailList from '../modules/onlineAccount/checkerRepairList/components/Details'
import MakerRepairDetaiList from '../modules/onlineAccount/makerRepairList/components/Details'
import NewMakerList from '../modules/onlineAccount/newMakerList/components'
import NewMakerDetaiList from '../modules/onlineAccount/newMakerList/components/Details'

import AccountOpen from '../modules/onlineAccount/accountOpen/components'

import AccountOpenDetail from '../modules/onlineAccount/accountOpen/components/Details'
import CheckerRepairList from '../modules/onlineAccount/checkerRepairList/components'
import ProductManager from '../modules/products/productManager/components'
import FDProductManager from '../modules/fdProductManagement/fdProductManager/components'
import FdRequestUnverified from '../modules/fdRequest/fdRequestUnverified/components'
import Tenure from '../modules/fdProductManagement/tenure/component'
import FdRejectedList from '../modules/fdRequest/fdReject/components'
import FdRejectedDetailView from '../modules/fdRequest/fdReject/components/Details'
import FdApprovedList from '../modules/fdRequest/fdApproved/components'
import FdApprovedDetailView from '../modules/fdRequest/fdApproved/components/Details'
import TermAndCondition from '../modules/TermAndCondition/termAndConditionManager/component'
import FDRequestList from '../modules/fdRequest/fdRequestList/components'
import FDRequestDetails from '../modules/fdRequest/fdRequestList/components/Details'
import FDPreliminaryUnverifiedDetail from '../modules/fdRequest/fdRequestUnverified/components/Details'
import OnlineOnPrim from '../modules/onlineAccount/onlineOnPrim/components'
import OnlineOnPrimDetails from '../modules/onlineAccount/onlineOnPrim/components/Details'
import BannerManager from '../modules/prime-customer-portal/banner/bannerManager/components'

import ServiceManager from '../modules/prime-customer-portal/service/serviceManager/components'
import SubServiceManager from '../modules/prime-customer-portal/service/subServiceManger/components'

import CardManager from '../modules/prime-customer-portal/card/cardManager/components'

export interface RouteModel {
  path?: any
  component?: React.ComponentType<RouteComponentProps>
  exact?: boolean
}

export const RouteList = [
  {
    path: '/dashboard',
    component: DashboardWrapper,
    exact: true,
  },
  {
    path: '/cms/content',
    component: ContentManager,
    exact: true,
  },

  {
    path: '/cms/menu-manager',
    component: MenuManager,
    exact: true,
  },
  {
    path: '/cms/banner',
    component: Banner,
    exact: true,
  },

  {
    path: '/popup/text-popup',
    component: TextPopup,
    exact: true,
  },

  {
    path: '/popup/road-block-popup',
    component: RoadBlockPopup,
    exact: true,
  },
  {
    path: '/cms/manage-faqs',
    component: FaqCategory,
    exact: true,
  },
  {
    path: '/cms/update-faqs/:id',
    component: FaqManager,
    exact: true,
  },
  {
    path: '/cms/email-template',
    component: EmailTemplate,
    exact: true,
  },

  {
    path: '/usermanagement/users',
    component: UserManagementIndex,
    exact: true,
  },
  {
    path: '/usermanagement/user-role',
    component: UserRoleManagementIndex,
    exact: true,
  },
  {
    path: '/usermanagement/role',
    component: RoleManagementIndex,
    exact: true,
  },
  {
    path: '/usermanagement/departments',
    component: Departments,
    exact: true,
  },
  {
    path: '/sitesetting/module',
    component: ModuleManager,
    exact: true,
  },
  {
    path: '/sitesetting/contact-setting',
    component: ContactSetting,
    exact: true,
  },
  {
    path: '/sitesetting/homepage-setting',
    component: HomepageSetting,
    exact: true,
  },
  {
    path: '/sitesetting/backend-setting',
    component: BackendSettings,
    exact: true,
  },
  {
    path: '/sitesetting/setting',
    component: Settings,
    exact: true,
  },
  {
    path: '/sitesetting/smtp-setting',
    component: SmtpSettings,
    exact: true,
  },
  {
    path: '/sitesetting/key-setting',
    component: KeySettings,
    exact: true,
  },

  {
    path: '/network/branch',
    component: BranchComponent,
    exact: true,
  },

  //ENquiry Module
  {
    path: '/inquiry/inquiry-types',
    component: EnquiryTypeComponent,
    exact: true,
  },
  {
    path: '/inquiry/inquiry-manager',
    component: EnquiryComponent,
    exact: true,
  },

  {
    path: '/cms/forms-and-download',
    component: FormDownloadComponent,
    exact: true,
  },

  {
    path: '/online-account/preliminary-verified',
    component: PreliminaryVerified,
    exact: true,
  },

  {
    path: '/online-account/preliminary-unverified',
    component: PreliminaryUnverified,
    exact: true,
  },

  {
    path: '/online-account/checker-list',
    component: CheckerList,
    exact: true,
  },
  {
    path: '/online-account/checker-list/:id',
    component: CheckerDetaiList,
    exact: true,
  },

  {
    path: '/online-account/maker-list',
    component: MakerList,
    exact: true,
  },

  {
    path: '/online-account/new-maker-list',
    component: NewMakerList,
    exact: true,
  },

  {
    path: '/online-account/pending-accounts',
    component: PendingAccountList,
    exact: true,
  },

  {
    path: '/online-account/rejected-list',
    component: RejectedList,
    exact: true,
  },

  {
    path: '/online-account/maker-repair-list',
    component: MakerRepairList,
    exact: true,
  },

  {
    path: '/online-account/checker-repair-list',
    component: CheckerRepairList,
    exact: true,
  },

  {
    path: '/online-account/preliminary-verified/:id',
    component: PreliminaryVerifiedDetail,
    exact: true,
  },

  {
    path: '/online-account/preliminary-unverified/:id',
    component: PreliminaryUnverifiedDetail,
    exact: true,
  },

  {
    path: '/online-account/maker-list/:id',
    component: MakerDetaiList,
    exact: true,
  },

  {
    path: '/online-account/new-maker-list/:id',
    component: NewMakerDetaiList,
    exact: true,
  },

  {
    path: '/online-account/maker-repair-list/:id',
    component: MakerRepairDetaiList,
    exact: true,
  },

  {
    path: '/online-account/checker-repair-list/:id',
    component: CheckerRepairDetailList,
    exact: true,
  },

  {
    path: '/online-account/account-open',
    component: AccountOpen,
    exact: true,
  },

  {
    path: '/online-account/account-open/:id',
    component: AccountOpenDetail,
    exact: true,
  },

  {
    path: '/online-account/pending-accounts/:id',
    component: PendingAccountDetailList,
    exact: true,
  },
  {
    path: '/online-account/rejected-list/:id',
    component: AccountOpeningRejectedList,
    exact: true,
  },
  {
    path: '/online-account/onlineOnPrim',
    component: OnlineOnPrim,
    exact: true,
  },
  {
    path: '/online-account/onlineOnPrim/:id',
    component: OnlineOnPrimDetails,
    exact: true,
  },
  {
    path: '/products/product-manager',
    component: ProductManager,
    exact: true,
  },
  {
    path: '/fd-product-management/fd-product-manager',
    component: FDProductManager,
    exact: true,
  },
  {
    path: '/fd-product-management/tenure',
    component: Tenure,
    exact: true,
  },
  {
    path: '/term-and-condition/term-and-condition-manager',
    component: TermAndCondition,
    exact: true,
  },
  {
    path: '/fd-request/fd-reject-list',
    component: FdRejectedList,
    exact: true,
  },
  {
    path: '/fd-request/fd-rejected/:id',
    component: FdRejectedDetailView,
    exact: true,
  },
  {
    path: '/fd-request/fd-request-unverified-list',
    component: FdRequestUnverified,
    exact: true,
  },
  {
    path: '/fd-request/fd-request-unverified/:id',
    component: FDPreliminaryUnverifiedDetail,
    exact: true,
  },
  {
    path: '/fd-request/fd-request-list',
    component: FDRequestList,
    exact: true,
  },
  {
    path: '/fd-request/fd-requested/:id',
    component: FDRequestDetails,
    exact: true,
  },
  {
    path: '/fd-request/fd-approved-list',
    component: FdApprovedList,
    exact: true,
  },
  {
    path: '/fd-request/fd-approved/:id',
    component: FdApprovedDetailView,
    exact: true,
  },
  {
    path: '/prime-customer-portal/banner/banner-manager',
    component: BannerManager,
    exact: true,
  },
  {
    path: '/prime-customer-portal/service/service-manager',
    component: ServiceManager,
    exact: true,
  },
  {
    path: '/prime-customer-portal/service/sub-service-manager',
    component: SubServiceManager,
    exact: true,
  },
  {
    path: '/prime-customer-portal/card/card-manager',
    component: CardManager,
    exact: true,
  },
]
