export const actionTypes = {
  GET_ALL_CARD_MANAGER_START: 'GET_ALL_CARD_MANAGER_START',
  GET_ALL_CARD_MANAGER_SUCCESS: 'GET_ALL_CARD_MANAGER_SUCCESS',
  GET_ALL_CARD_MANAGER_FINISH: 'GET_ALL_CARD_MANAGER_FINISH',

  ADD_CARD_MANAGER_START: 'ADD_CARD_MANAGER_START',
  ADD_CARD_MANAGER_SUCCESS: 'ADD_CARD_MANAGER_SUCCESS',
  ADD_CARD_MANAGER_FINISH: 'ADD_CARD_MANAGER_FINISH',
  RESET_CARD_MANAGER: 'RESET_CARD_MANAGER',

  UPDATE_CARD_MANAGER_START: 'UPDATE_CARD_MANAGER_START',
  UPDATE_CARD_MANAGER_SUCCESS: 'UPDATE_CARD_MANAGER_SUCCESS',
  UPDATE_CARD_MANAGER_FINISH: 'UPDATE_CARD_MANAGER_FINISH',

  DELETE_CARD_MANAGER_START: 'DELETE_CARD_MANAGER_START',
  DELETE_CARD_MANAGER_SUCCESS: 'DELETE_CARD_MANAGER_SUCCESS',
  DELETE_CARD_MANAGER_FINISH: 'DELETE_CARD_MANAGER_FINISH',

  SINGLE_DISABLE_CARD_MANAGER_REQUEST: 'SINGLE_DISABLE_CARD_MANAGER_REQUEST',
  SINGLE_DISABLE_CARD_MANAGER_SUCCESS: 'SINGLE_DISABLE_CARD_MANAGER_SUCCESS',
  SINGLE_DISABLE_CARD_MANAGER_FINISH: 'SINGLE_DISABLE_CARD_MANAGER_FINISH',

  SINGLE_ENABLE_CARD_MANAGER_REQUEST: 'SINGLE_ENABLE_CARD_MANAGER_REQUEST',
  SINGLE_ENABLE_CARD_MANAGER_SUCCESS: 'SINGLE_ENABLE_CARD_MANAGER_SUCCESS',
  SINGLE_ENABLE_CARD_MANAGER_FINISH: 'SINGLE_ENABLE_CARD_MANAGER_FINISH',

  ENABLE_CARD_MANAGER_REQUEST: 'ENABLE_CARD_MANAGER_REQUEST',
  ENABLE_CARD_MANAGER_SUCCESS: 'ENABLE_CARD_MANAGER_SUCCESS',
  ENABLE_CARD_MANAGER_FINISH: 'ENABLE_CARD_MANAGER_FINISH',

  // Disable CARD_TAG
  DISABLE_CARD_MANAGER_REQUEST: 'DISABLE_CARD_MANAGER_REQUEST',
  DISABLE_CARD_MANAGER_SUCCESS: 'DISABLE_CARD_MANAGER_SUCCESS',
  DISABLE_CARD_MANAGER_FINISH: 'DISABLE_CARD_MANAGER_FINISH',

  // sort
  SORT_CARD_MANAGER_START: 'SORT_CARD_MANAGER_START',
  SORT_CARD_MANAGER_SUCCESS: 'SORT_CARD_MANAGER_SUCCESS',
  SORT_CARD_MANAGER_FINISH: 'SORT_CARD_MANAGER_FINISH',
  SORT_CARD_MANAGER_RESET: 'SORT_CARD_MANAGER_RESET',
}
