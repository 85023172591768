import {Action} from 'redux'
import {actionTypes} from './constants'
import {CardManagerModel} from '../Model'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface ICardManagerState {
  data?: {
    card: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }
  sortCardManagerData: ICardManagerState[]
  // allCardManager?: CardManagerModel[]
  allCardManager?: {card: any[] | any}
  deleteSuccess?: boolean
  enableSuccess?: boolean
  disableSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
  loading?: boolean
  success?: boolean
}

const initialCardManagerState: ICardManagerState = {
  data: {
    card: [],
    meta: {},
  },
  sortCardManagerData: [],
  allCardManager: {card: []},
  deleteSuccess: false,
  enableSuccess: false,
  disableSuccess: false,
  singleEnableSuccess: false,
  singleDisableSuccess: false,
  loading: false,
  success: false,
}

export const reducer = (
  state: ICardManagerState = initialCardManagerState,
  action: ActionWithPayload<ICardManagerState>
) => {
  switch (action.type) {
    case actionTypes.GET_ALL_CARD_MANAGER_START: {
      return {
        ...state,
        loading: true,
      }
    }

    case actionTypes.GET_ALL_CARD_MANAGER_SUCCESS: {
      return {
        ...state,
        // allCardManager: action.payload,
        data: action.payload?.data,
        loading: false,
      }
    }

    case actionTypes.GET_ALL_CARD_MANAGER_FINISH: {
      const error = action.payload
      return {
        ...state,
        error,
        success: false,
        loading: false,
      }
    }

    //Enable
    case actionTypes.SINGLE_ENABLE_CARD_MANAGER_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_ENABLE_CARD_MANAGER_SUCCESS: {
      const changedData: any = action.payload?.data?.card
      let newData = state?.data?.card?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })
      return {
        ...state,
        data: {...state, meta: state?.data?.meta, cardManager: newData},
        singleEnableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_ENABLE_CARD_MANAGER_FINISH: {
      return {
        ...state,
        singleEnableSuccess: false,
        toggleLoading: false,
      }
    }

    //Disable
    case actionTypes.SINGLE_DISABLE_CARD_MANAGER_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_DISABLE_CARD_MANAGER_SUCCESS: {
      const changedData: any = action.payload?.data?.card
      let newData = state?.data?.card?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: {...state, meta: state?.data?.meta, cardManager: newData},
        singleDisableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_DISABLE_CARD_MANAGER_FINISH: {
      return {
        ...state,
        singleDisableSuccess: false,
        toggleLoading: false,
      }
    }

    //Enable
    case actionTypes.ENABLE_CARD_MANAGER_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.ENABLE_CARD_MANAGER_SUCCESS: {
      return {
        ...state,
        enableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.ENABLE_CARD_MANAGER_FINISH: {
      return {
        ...state,
        loading: false,
        enableSuccess: false,
      }
    }

    //disable
    case actionTypes.DISABLE_CARD_MANAGER_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.DISABLE_CARD_MANAGER_SUCCESS: {
      return {
        ...state,
        disableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DISABLE_CARD_MANAGER_FINISH: {
      return {
        ...state,
        loading: false,
        disableSuccess: false,
      }
    }

    case actionTypes.DELETE_CARD_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_CARD_MANAGER_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_CARD_MANAGER_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    case actionTypes.ADD_CARD_MANAGER_START: {
      return {
        ...state,
        loading: true,
      }
    }

    case actionTypes.ADD_CARD_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_CARD_MANAGER_FINISH: {
      const error = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    }

    case actionTypes.RESET_CARD_MANAGER: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    case actionTypes.UPDATE_CARD_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_CARD_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_CARD_MANAGER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    case actionTypes.SORT_CARD_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.SORT_CARD_MANAGER_SUCCESS: {
      return {
        ...state,
        sortCardManagerData: action?.payload,
        success: true,
        loading: false,
      }
    }

    case actionTypes.SORT_CARD_MANAGER_FINISH: {
      return {
        ...state,
        loading: false,
        success: false,
      }
    }

    case actionTypes.SORT_CARD_MANAGER_RESET: {
      return {
        ...state,
        loading: false,
        success: false,
        sortCardManagerData: [],
      }
    }

    default:
      return state
  }
}
