import {Action} from 'redux'
import {actionTypes} from './constants'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export interface IServiceManagerState {
  data?: {
    service: {[key: string]: number | string}[]
    meta: {[key: string]: number}
  }
  sortServiceManagerData: IServiceManagerState[]
  allServiceManager?: {service: any[] | any}
  deleteSuccess?: boolean
  enableSuccess?: boolean
  disableSuccess?: boolean
  singleEnableSuccess?: boolean
  singleDisableSuccess?: boolean
  loading?: boolean
  success?: boolean
}

const initialServiceManagerState: IServiceManagerState = {
  data: {
    service: [],
    meta: {},
  },
  sortServiceManagerData: [],
  allServiceManager: {service: []},
  deleteSuccess: false,
  enableSuccess: false,
  disableSuccess: false,
  singleEnableSuccess: false,
  singleDisableSuccess: false,
  loading: false,
  success: false,
}

export const reducer = (
  state: IServiceManagerState = initialServiceManagerState,
  action: ActionWithPayload<IServiceManagerState>
) => {
  switch (action.type) {
    case actionTypes.GET_ALL_SERVICE_MANAGER_START: {
      return {
        ...state,
        loading: true,
      }
    }

    case actionTypes.GET_ALL_SERVICE_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action.payload?.data,
        loading: false,
      }
    }

    case actionTypes.GET_ALL_SERVICE_MANAGER_FINISH: {
      const error = action.payload
      return {
        ...state,
        error,
        success: false,
        loading: false,
      }
    }

    //Enable
    case actionTypes.SINGLE_ENABLE_SERVICE_MANAGER_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_ENABLE_SERVICE_MANAGER_SUCCESS: {
      const changedData: any = action.payload?.data?.service
      let newData = state?.data?.service?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })
      return {
        ...state,
        data: {...state, meta: state?.data?.meta, service: newData},
        singleEnableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_ENABLE_SERVICE_MANAGER_FINISH: {
      return {
        ...state,
        singleEnableSuccess: false,
        toggleLoading: false,
      }
    }

    //Disable
    case actionTypes.SINGLE_DISABLE_SERVICE_MANAGER_REQUEST: {
      return {...state, toggleLoading: true}
    }

    case actionTypes.SINGLE_DISABLE_SERVICE_MANAGER_SUCCESS: {
      const changedData: any = action.payload?.data?.service
      let newData = state?.data?.service?.map((data) => {
        if (data?.id === changedData[0]?.id) {
          return {
            ...data,
            status: !data?.status,
          }
        } else {
          return data
        }
      })

      return {
        ...state,
        data: {...state, meta: state?.data?.meta, service: newData},
        singleDisableSuccess: true,
        toggleLoading: false,
      }
    }

    case actionTypes.SINGLE_DISABLE_SERVICE_MANAGER_FINISH: {
      return {
        ...state,
        singleDisableSuccess: false,
        toggleLoading: false,
      }
    }

    //Enable
    case actionTypes.ENABLE_SERVICE_MANAGER_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.ENABLE_SERVICE_MANAGER_SUCCESS: {
      return {
        ...state,
        enableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.ENABLE_SERVICE_MANAGER_FINISH: {
      return {
        ...state,
        loading: false,
        enableSuccess: false,
      }
    }

    //disable
    case actionTypes.DISABLE_SERVICE_MANAGER_REQUEST: {
      return {...state, loading: true}
    }

    case actionTypes.DISABLE_SERVICE_MANAGER_SUCCESS: {
      return {
        ...state,
        disableSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DISABLE_SERVICE_MANAGER_FINISH: {
      return {
        ...state,
        loading: false,
        disableSuccess: false,
      }
    }

    case actionTypes.DELETE_SERVICE_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.DELETE_SERVICE_MANAGER_SUCCESS: {
      return {
        ...state,
        deleteSuccess: true,
        loading: false,
      }
    }

    case actionTypes.DELETE_SERVICE_MANAGER_FINISH: {
      return {
        ...state,
        loading: false,
        deleteSuccess: false,
      }
    }

    case actionTypes.ADD_SERVICE_MANAGER_START: {
      return {
        ...state,
        loading: true,
      }
    }

    case actionTypes.ADD_SERVICE_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.ADD_SERVICE_MANAGER_FINISH: {
      const error = action.payload
      return {
        ...state,
        error,
        loading: false,
      }
    }

    case actionTypes.RESET_SERVICE_MANAGER: {
      return {
        ...state,
        data: [],
        success: false,
      }
    }

    case actionTypes.UPDATE_SERVICE_MANAGER_START: {
      return {...state, loading: true}
    }

    case actionTypes.UPDATE_SERVICE_MANAGER_SUCCESS: {
      return {
        ...state,
        data: action?.payload?.data,
        success: true,
        loading: false,
      }
    }

    case actionTypes.UPDATE_SERVICE_MANAGER_FINISH: {
      const error = action.payload
      return {...state, error, loading: false}
    }

    default:
      return state
  }
}
