export const actionTypes = {
  GET_ALL_SUB_SERVICE_MANAGER_START: 'GET_ALL_SUB_SERVICE_MANAGER_START',
  GET_ALL_SUB_SERVICE_MANAGER_SUCCESS: 'GET_ALL_SUB_SERVICE_MANAGER_SUCCESS',
  GET_ALL_SUB_SERVICE_MANAGER_FINISH: 'GET_ALL_SUB_SERVICE_MANAGER_FINISH',

  ADD_SUB_SERVICE_MANAGER_START: 'ADD_SUB_SERVICE_MANAGER_START',
  ADD_SUB_SERVICE_MANAGER_SUCCESS: 'ADD_SUB_SERVICE_MANAGER_SUCCESS',
  ADD_SUB_SERVICE_MANAGER_FINISH: 'ADD_SUB_SERVICE_MANAGER_FINISH',
  RESET_SUB_SERVICE_MANAGER: 'RESET_SUB_SERVICE_MANAGER',

  UPDATE_SUB_SERVICE_MANAGER_START: 'UPDATE_SUB_SERVICE_MANAGER_START',
  UPDATE_SUB_SERVICE_MANAGER_SUCCESS: 'UPDATE_SUB_SERVICE_MANAGER_SUCCESS',
  UPDATE_SUB_SERVICE_MANAGER_FINISH: 'UPDATE_SUB_SERVICE_MANAGER_FINISH',

  DELETE_SUB_SERVICE_MANAGER_START: 'DELETE_SUB_SERVICE_MANAGER_START',
  DELETE_SUB_SERVICE_MANAGER_SUCCESS: 'DELETE_SUB_SERVICE_MANAGER_SUCCESS',
  DELETE_SUB_SERVICE_MANAGER_FINISH: 'DELETE_SUB_SERVICE_MANAGER_FINISH',

  SINGLE_DISABLE_SUB_SERVICE_MANAGER_REQUEST: 'SINGLE_DISABLE_SUB_SERVICE_MANAGER_REQUEST',
  SINGLE_DISABLE_SUB_SERVICE_MANAGER_SUCCESS: 'SINGLE_DISABLE_SUB_SERVICE_MANAGER_SUCCESS',
  SINGLE_DISABLE_SUB_SERVICE_MANAGER_FINISH: 'SINGLE_DISABLE_SUB_SERVICE_MANAGER_FINISH',

  SINGLE_ENABLE_SUB_SERVICE_MANAGER_REQUEST: 'SINGLE_ENABLE_SUB_SERVICE_MANAGER_REQUEST',
  SINGLE_ENABLE_SUB_SERVICE_MANAGER_SUCCESS: 'SINGLE_ENABLE_SUB_SERVICE_MANAGER_SUCCESS',
  SINGLE_ENABLE_SUB_SERVICE_MANAGER_FINISH: 'SINGLE_ENABLE_SUB_SERVICE_MANAGER_FINISH',

  ENABLE_SUB_SERVICE_MANAGER_REQUEST: 'ENABLE_SUB_SERVICE_MANAGER_REQUEST',
  ENABLE_SUB_SERVICE_MANAGER_SUCCESS: 'ENABLE_SUB_SERVICE_MANAGER_SUCCESS',
  ENABLE_SUB_SERVICE_MANAGER_FINISH: 'ENABLE_SUB_SERVICE_MANAGER_FINISH',

  DISABLE_SUB_SERVICE_MANAGER_REQUEST: 'DISABLE_SUB_SERVICE_MANAGER_REQUEST',
  DISABLE_SUB_SERVICE_MANAGER_SUCCESS: 'DISABLE_SUB_SERVICE_MANAGER_SUCCESS',
  DISABLE_SUB_SERVICE_MANAGER_FINISH: 'DISABLE_SUB_SERVICE_MANAGER_FINISH',
}
