import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {actions} from './actions'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {actionTypes} from './constants'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'
import {SortCardManagerActionModel, SortCardManagerModel} from '../Model'

function* getAllCardManagerSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getAllCardManager, params)
    const data: any = response?.data
    yield put(actions.getAllCardManagerSuccess(data))
  } catch (error: any) {
    yield put(actions.getAllCardManagerError(error))
  }
}

function* enableCardManagerSaga(action: ActionModel) {
  try {
    const selectedCards: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableCardManager, selectedCards)
    yield put(actions.enableCardManagerSuccess(response?.data))
    yield put(actions.enableCardManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableCardManagerFinish())
  }
}

function* disableCardManagerSaga(action: ActionModel) {
  try {
    const selectedCards: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableCardManager, selectedCards)
    yield put(actions.disableCardManagerSuccess(response?.data))
    yield put(actions.disableCardManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableCardManagerFinish())
  }
}

function* singleEnableCardManagerSaga(action: ActionModel) {
  try {
    const selectedCards: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableCardManager, selectedCards)
    yield put(actions.singleEnableCardManagerSuccess(response?.data))
    yield put(actions.singleEnableCardManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableCardManagerFinish())
  }
}

function* singleDisableCardManagerSaga(action: ActionModel) {
  try {
    const selectedUsers: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableCardManager, selectedUsers)
    yield put(actions.singleDisableCardManagerSuccess(response?.data))
    yield put(actions.singleDisableCardManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableCardManagerFinish())
  }
}

function* deleteCardManagerSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteCardManager, body)
    yield put(actions.deleteCardManagerSuccess(response.data?.data))
    yield put(actions.deleteCardManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteCardManagerFinish())
  }
}

function* addCardManagerSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addCardManager, body)
    yield put(actions.addCardManagerSuccess(response.data?.data))
    yield put(actions.addCardManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addCardManagerFinish())
  }
}

function* updateCardManagerSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.updateCardManager, body, action.payload?.id)
    yield put(actions.updateCardManagerSuccess(response.data?.data))
    yield put(actions.updateCardManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateCardManagerFinish())
  }
}

function* sortCardManager(action: SortCardManagerActionModel) {
  try {
    const body: SortCardManagerModel = action.payload
    const response: ResponseModel = yield call(service.sortCardManager, body)

    yield put({
      type: actionTypes.SORT_CARD_MANAGER_SUCCESS,
      payload: response?.data?.data,
    })
    yield put({type: actionTypes.SORT_CARD_MANAGER_FINISH})
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put({type: actionTypes.SORT_CARD_MANAGER_FINISH})
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_ALL_CARD_MANAGER_START, getAllCardManagerSaga)
  yield takeLatest(actionTypes.ENABLE_CARD_MANAGER_REQUEST, enableCardManagerSaga)
  yield takeLatest(actionTypes.DISABLE_CARD_MANAGER_REQUEST, disableCardManagerSaga)
  yield takeLatest(actionTypes.SINGLE_ENABLE_CARD_MANAGER_REQUEST, singleEnableCardManagerSaga)
  yield takeLatest(actionTypes.SINGLE_DISABLE_CARD_MANAGER_REQUEST, singleDisableCardManagerSaga)
  yield takeLatest(actionTypes.DELETE_CARD_MANAGER_START, deleteCardManagerSaga)
  yield takeLatest(actionTypes.ADD_CARD_MANAGER_START, addCardManagerSaga)
  yield takeLatest(actionTypes.UPDATE_CARD_MANAGER_START, updateCardManagerSaga)
  yield takeLatest(actionTypes.SORT_CARD_MANAGER_START, sortCardManager)
}
