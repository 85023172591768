import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {actions} from './actions'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {actionTypes} from './constants'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'

function* getAllSubServiceManagerSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getAllSubServiceManager, params)
    const data: any = response?.data
    yield put(actions.getAllSubServiceManagerSuccess(data))
  } catch (error: any) {
    yield put(actions.getAllSubServiceManagerError(error))
  }
}

function* enableSubServiceManagerSaga(action: ActionModel) {
  try {
    const selectedSubServices: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableSubServiceManager, selectedSubServices)
    yield put(actions.enableSubServiceManagerSuccess(response?.data))
    yield put(actions.enableSubServiceManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableSubServiceManagerFinish())
  }
}

function* disableSubServiceManagerSaga(action: ActionModel) {
  try {
    const selectedSubServices: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.disableSubServiceManager,
      selectedSubServices
    )
    yield put(actions.disableSubServiceManagerSuccess(response?.data))
    yield put(actions.disableSubServiceManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.disableSubServiceManagerFinish())
  }
}

function* singleEnableSubServiceManagerSaga(action: ActionModel) {
  try {
    const selectedSubServices: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.singleEnableSubServiceManager,
      selectedSubServices
    )
    yield put(actions.singleEnableSubServiceManagerSuccess(response?.data))
    yield put(actions.singleEnableSubServiceManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableSubServiceManagerFinish())
  }
}

function* singleDisableSubServiceManagerSaga(action: ActionModel) {
  try {
    const selectedSubServices: any = action.payload?.data
    const response: ResponseModel = yield call(
      service.singleDisableSubServiceManager,
      selectedSubServices
    )
    yield put(actions.singleDisableSubServiceManagerSuccess(response?.data))
    yield put(actions.singleDisableSubServiceManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableSubServiceManagerFinish())
  }
}

function* deleteSubServiceManagerSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteSubServiceManager, body)
    yield put(actions.deleteSubServiceManagerSuccess(response.data?.data))
    yield put(actions.deleteSubServiceManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteSubServiceManagerFinish())
  }
}

function* addSubServiceManagerSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addSubServiceManager, body)
    yield put(actions.addSubServiceManagerSuccess(response.data?.data))
    yield put(actions.addSubServiceManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addSubServiceManagerFinish())
  }
}

function* updateSubServiceManagerSaga(action: ActionModel) {
  console.log(action, 'saga')
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(
      service.updateSubServiceManager,
      body,
      action?.payload?.id
    )
    yield put(actions.updateSubServiceManagerSuccess(response.data?.data))
    yield put(actions.updateSubServiceManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateSubServiceManagerFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_ALL_SUB_SERVICE_MANAGER_START, getAllSubServiceManagerSaga)
  yield takeLatest(actionTypes.ENABLE_SUB_SERVICE_MANAGER_REQUEST, enableSubServiceManagerSaga)
  yield takeLatest(actionTypes.DISABLE_SUB_SERVICE_MANAGER_REQUEST, disableSubServiceManagerSaga)
  yield takeLatest(
    actionTypes.SINGLE_ENABLE_SUB_SERVICE_MANAGER_REQUEST,
    singleEnableSubServiceManagerSaga
  )
  yield takeLatest(
    actionTypes.SINGLE_DISABLE_SUB_SERVICE_MANAGER_REQUEST,
    singleDisableSubServiceManagerSaga
  )
  yield takeLatest(actionTypes.DELETE_SUB_SERVICE_MANAGER_START, deleteSubServiceManagerSaga)
  yield takeLatest(actionTypes.ADD_SUB_SERVICE_MANAGER_START, addSubServiceManagerSaga)
  yield takeLatest(actionTypes.UPDATE_SUB_SERVICE_MANAGER_START, updateSubServiceManagerSaga)
}
