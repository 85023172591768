import {call, put, takeLatest, delay} from 'redux-saga/effects'
import {ActionModel} from 'src/cms/helpers/Models/ActionModel'
import {actions} from './actions'
import {ResponseModel} from 'src/cms/helpers/Models/ResponseModel'
import {service} from './service'
import {actionTypes} from './constants'
import {globalActionTypes} from 'src/app/modules/errors/redux/constants'

function* getAllBannerManagerSaga(action: ActionModel) {
  try {
    const params: any = action.payload?.params
    yield delay(500)
    const response: ResponseModel = yield call(service.getAllBannerManager, params)
    const data: any = response?.data
    yield put(actions.getAllBannerManagerSuccess(data))
  } catch (error: any) {
    yield put(actions.getAllBannerManagerError(error))
  }
}

function* enableBannerManagerSaga(action: ActionModel) {
  try {
    const selectedBanners: any = action.payload?.data
    const response: ResponseModel = yield call(service.enableBannerManager, selectedBanners)
    yield put(actions.enableBannerManagerSuccess(response?.data))
    yield put(actions.enableBannerManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.enableBannerManagerFinish())
  }
}

function* disableBannerManagerSaga(action: ActionModel) {
  try {
    const selectedBanners: any = action.payload?.data
    const response: ResponseModel = yield call(service.disableBannerManager, selectedBanners)
    yield put(actions.disableBannerManagerSuccess(response?.data))
    yield put(actions.disableBannerManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.disableBannerManagerFinish())
  }
}

function* singleEnableBannerManagerSaga(action: ActionModel) {
  try {
    const selectedBanners: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleEnableBannerManager, selectedBanners)
    yield put(actions.singleEnableBannerManagerSuccess(response?.data))
    yield put(actions.singleEnableBannerManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleEnableBannerManagerFinish())
  }
}

function* singleDisableBannerManagerSaga(action: ActionModel) {
  try {
    const selectedBanners: any = action.payload?.data
    const response: ResponseModel = yield call(service.singleDisableBannerManager, selectedBanners)
    yield put(actions.singleDisableBannerManagerSuccess(response?.data))
    yield put(actions.singleDisableBannerManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.singleDisableBannerManagerFinish())
  }
}

function* deleteBannerManagerSaga(action: ActionModel) {
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(service.deleteBannerManager, body)
    yield put(actions.deleteBannerManagerSuccess(response.data?.data))
    yield put(actions.deleteBannerManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.deleteBannerManagerFinish())
  }
}

function* addBannerManagerSaga(action: ActionModel) {
  try {
    const body = action.payload
    const response: ResponseModel = yield call(service.addBannerManager, body)
    yield put(actions.addBannerManagerSuccess(response.data?.data))
    yield put(actions.addBannerManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.addBannerManagerFinish())
  }
}

function* updateBannerManagerSaga(action: ActionModel) {
  console.log(action, 'saga')
  try {
    const body: any = action.payload
    const response: ResponseModel = yield call(
      service.updateBannerManager,
      body,
      action?.payload?.id
    )
    yield put(actions.updateBannerManagerSuccess(response.data?.data))
    yield put(actions.updateBannerManagerFinish())
  } catch (err: any) {
    if (err.response) {
      yield put({type: globalActionTypes.GLOBAL_ERROR, payload: err.response.data.message})
    }
    yield put(actions.updateBannerManagerFinish())
  }
}

export function* saga() {
  yield takeLatest(actionTypes.GET_ALL_BANNER_MANAGER_START, getAllBannerManagerSaga)
  yield takeLatest(actionTypes.ENABLE_BANNER_MANAGER_REQUEST, enableBannerManagerSaga)
  yield takeLatest(actionTypes.DISABLE_BANNER_MANAGER_REQUEST, disableBannerManagerSaga)
  yield takeLatest(actionTypes.SINGLE_ENABLE_BANNER_MANAGER_REQUEST, singleEnableBannerManagerSaga)
  yield takeLatest(
    actionTypes.SINGLE_DISABLE_BANNER_MANAGER_REQUEST,
    singleDisableBannerManagerSaga
  )
  yield takeLatest(actionTypes.DELETE_BANNER_MANAGER_START, deleteBannerManagerSaga)
  yield takeLatest(actionTypes.ADD_BANNER_MANAGER_START, addBannerManagerSaga)
  yield takeLatest(actionTypes.UPDATE_BANNER_MANAGER_START, updateBannerManagerSaga)
}
