import {ParamsModel} from 'src/app/modules/common/Model'
import {BannerManagerModel} from '../Model'
import {actionTypes} from './constants'

export const actions = {
  getAllBannerManager: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_ALL_BANNER_MANAGER_START,
    payload: {params},
  }),

  getAllBannerManagerSuccess: (data: BannerManagerModel | any) => ({
    type: actionTypes.GET_ALL_BANNER_MANAGER_SUCCESS,
    payload: data,
  }),

  getAllBannerManagerError: (data: any) => ({
    type: actionTypes.GET_ALL_BANNER_MANAGER_FINISH,
    payload: data,
  }),

  enableBannerManager: (data: {id: string}[]) => ({
    type: actionTypes.ENABLE_BANNER_MANAGER_REQUEST,
    payload: {data},
  }),

  enableBannerManagerSuccess: (task: any) => ({
    type: actionTypes.ENABLE_BANNER_MANAGER_SUCCESS,
    payload: task,
  }),
  enableBannerManagerFinish: () => ({
    type: actionTypes.ENABLE_BANNER_MANAGER_FINISH,
  }),

  disableBannerManager: (data: {id: string}[]) => ({
    type: actionTypes.DISABLE_BANNER_MANAGER_REQUEST,
    payload: {data},
  }),

  disableBannerManagerSuccess: (task: any) => ({
    type: actionTypes.DISABLE_BANNER_MANAGER_SUCCESS,
    payload: task,
  }),
  disableBannerManagerFinish: () => ({
    type: actionTypes.DISABLE_BANNER_MANAGER_FINISH,
  }),

  singleDisableBannerManager: (data: {id: string}) => ({
    type: actionTypes.SINGLE_DISABLE_BANNER_MANAGER_REQUEST,
    payload: {data},
  }),

  singleDisableBannerManagerSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_BANNER_MANAGER_SUCCESS,
    payload: task,
  }),

  singleDisableBannerManagerFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_BANNER_MANAGER_FINISH,
  }),

  singleEnableBannerManager: (data: {id: string}) => ({
    type: actionTypes.SINGLE_ENABLE_BANNER_MANAGER_REQUEST,
    payload: {data},
  }),

  singleEnableBannerManagerSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_BANNER_MANAGER_SUCCESS,
    payload: task,
  }),

  singleEnableBannerManagerFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_BANNER_MANAGER_FINISH,
  }),

  deleteBannerManager: (data: {id: string}[]) => ({
    type: actionTypes.DELETE_BANNER_MANAGER_START,
    payload: {bannerId: data},
  }),

  deleteBannerManagerSuccess: (data: any) => ({
    type: actionTypes.DELETE_BANNER_MANAGER_SUCCESS,
    payload: data,
  }),

  deleteBannerManagerFinish: () => ({
    type: actionTypes.DELETE_BANNER_MANAGER_FINISH,
  }),

  addBannerManager: (data: BannerManagerModel | any) => ({
    type: actionTypes.ADD_BANNER_MANAGER_START,
    payload: data,
  }),

  addBannerManagerSuccess: (data: BannerManagerModel | any) => ({
    type: actionTypes.ADD_BANNER_MANAGER_SUCCESS,
    payload: data,
  }),

  addBannerManagerFinish: () => ({
    type: actionTypes.ADD_BANNER_MANAGER_FINISH,
  }),

  resetBannerManager: () => ({
    type: actionTypes.RESET_BANNER_MANAGER,
  }),

  updateBannerManager: (data: BannerManagerModel | any) => ({
    type: actionTypes.UPDATE_BANNER_MANAGER_START,
    payload: data,
  }),

  updateBannerManagerSuccess: (data: any) => ({
    type: actionTypes.UPDATE_BANNER_MANAGER_SUCCESS,
    payload: data,
  }),

  updateBannerManagerFinish: () => ({
    type: actionTypes.UPDATE_BANNER_MANAGER_FINISH,
  }),
}
