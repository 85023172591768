import Modal from 'rsuite/Modal'
import { StateParamsModel } from 'src/app/modules/common/Model'
import { ErrorMessage, Form, Formik } from 'formik'
import FormTextBox from 'src/cms/helpers/components/forms/FormTextBox'
import FormInputMediaManager from 'src/cms/helpers/components/forms/FormInputMediaManager'
import { useEffect, useMemo, useState } from 'react'
import { imageBaseUrl } from 'src/cms/helpers/constants'
import { isEmpty } from 'lodash'
import FormTinyMce from 'src/cms/helpers/components/forms/FormTinyMce'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import * as subServiceManagerRedux from 'src/app/modules/prime-customer-portal/service/subServiceManger/redux'
import * as serviceManagerRedux from 'src/app/modules/prime-customer-portal/service/serviceManager/redux'
import { toast } from 'react-toastify'
import FormRadio from 'src/cms/helpers/components/forms/FormRadio'
import FormSelect from 'src/cms/helpers/components/forms/FormSelect'

const statusOptions = [
  { label: 'Active', value: 'Active' },
  { label: 'Inactive', value: 'Inactive' },
]

type Props = {
  open: boolean
  params?: StateParamsModel
  handleClose: () => void
  actionType?: string
  editSelectedData?: any
}

const AddSubServiceManager = ({ open, params, handleClose, actionType, editSelectedData }: Props) => {
  const dispatch = useDispatch()
  const [attachedFile, setAttachedFile] = useState('')
  const [attachfile, setAttachfile] = useState('')

  const { loading, success } = useSelector((state: any) => state.subServiceManager)
  const { data: serviceData } = useSelector((state: any) => state.serviceManager)

  const serviceOptions = useMemo(() => {
    if (!serviceData?.service || !serviceData?.service?.length) return []
    return serviceData?.service?.map((item: any) => ({ label: item?.title, value: item?.id }))
  }, [serviceData])


  useEffect(() => {
    dispatch(serviceManagerRedux.actions.getAllServiceManager({ page: 1, limit: 1000 }))
  }, [])

  useEffect(() => {
    if (success) {
      isEmpty(editSelectedData)
        ? toast.success('Sub service manager added successfully')
        : toast.success('Sub service manager edited successfully')
      dispatch(subServiceManagerRedux.actions.getAllSubServiceManager(params))
      dispatch(subServiceManagerRedux.actions.resetSubServiceManager())
      handleClose()
    }
  }, [success])

  const validationSchema = yup.object().shape({
    service_id: yup.string().required('Service is required'),
    title: yup.string().required('Title is required'),
    image: yup.string().required('Image is required'),
  })

  console.log(editSelectedData, 'editData')

  return (
    <div className='modal-container'>
      <Modal
        open={open}
        onClose={handleClose}
        className='w-75'
        backdrop='static'
        keyboard={false}
        enforceFocus={false}
      >
        <Modal.Header>
          <Modal.Title>{actionType} Sub Service Manager</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Formik
              initialValues={{
                service_id: editSelectedData?.service_id ?? '',
                title: editSelectedData?.title ?? '',
                description: editSelectedData?.description ?? '',
                image: editSelectedData?.image ?? '',
                first_button: editSelectedData?.first_button ?? '',
                first_button_link: editSelectedData?.first_button_link ?? '',
                second_button: editSelectedData?.second_button ?? "",
                second_button_link: editSelectedData?.second_button_link ?? "",
                status: editSelectedData?.status ? "Active" : "Inactive",
              }}
              onSubmit={(values) => {
                console.log(values, 'Submitted values')
                const formData = {
                  ...values,
                  status: values?.status === 'Active',
                }

                if (!isEmpty(editSelectedData)) {
                  dispatch(
                    subServiceManagerRedux.actions.updateSubServiceManager({ ...formData, id: editSelectedData?.id })
                  )
                } else {
                  dispatch(subServiceManagerRedux?.actions?.addSubServiceManager(formData))
                }
              }}
              validationSchema={validationSchema}
            >
              {({ isSubmitting, touched, handleChange, errors, values, setFieldValue }) => {
                return (
                  <Form>
                    <div className='card-body border-top pt-5'>
                      <div className='row'>
                        <div className='col-md-6 col-sm-6 col-xs-12'>
                          <FormSelect
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Please select service'
                            label='Service'
                            name='service_id'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            options={serviceOptions ?? []}
                            multiple={false}
                            values={values}
                            setFieldValue={setFieldValue}
                            required
                          />
                        </div>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='Title'
                            name='title'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            label='Title'
                            required
                          />
                        </div>


                      </div>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='First Button Name'
                            name='first_button'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            label='Button Name'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type='text'
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder='First Button Link'
                            name='first_button_link'
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            label='Button Link'
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type="text"
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder="Second Button Name"
                            name="second_button"
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            label='Second Button Name'
                          />
                        </div>

                        <div className='col-md-6 col-xs-12'>
                          <FormTextBox
                            type="text"
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            placeholder="Second Button Link"
                            name="second_button_link"
                            onChange={handleChange}
                            errors={errors}
                            touched={touched}
                            label='Second Button Link'
                          />
                        </div>
                      </div>

                      <div className='row'>
                        <div className='col-md-6 col-xs-12'>
                          <FormInputMediaManager
                            labelClassName='col-md-12'
                            containerClassName='col-md-12'
                            label='Image'
                            name='image'
                            setFieldValue={setFieldValue}
                            setImageUrl={setAttachedFile}
                            value={values?.image}
                            required
                          />
                          {!isEmpty(values?.image) ? (
                            <>
                              <li className='listing'>
                                <div className='thumbImageBlock'>
                                  <button
                                    type='button'
                                    title='Remove'
                                    className='btn thumbImage-remove d-flex align-items-center justify-content-center btn-primary'
                                    onClick={() => {
                                      setAttachfile('')
                                      setFieldValue('image', '')
                                    }}
                                  >
                                    X
                                  </button>

                                  <img
                                    className='thumbImage w-100 h-100'
                                    src={
                                      values?.image.slice(-3) == 'pdf'
                                        ? `/public/media/logos/pdfDefault.png`
                                        : `${imageBaseUrl}/${values?.image}`
                                    }
                                    alt=''
                                  />
                                </div>
                              </li>
                            </>
                          ) : null}
                        </div>
                        <div className='col-md-6 col-sm-12 col-xs-12'>
                          <label className='form-label fw-bolder text-dark fs-6'>
                            Status
                          </label>

                          <div className='d-flex ms-5'>
                            {statusOptions?.map((status: { label: string; value: string }) => (
                              <FormRadio
                                key={status?.label}
                                containerClassName=''
                                label='Select Status'
                                name='status'
                                type='radio'
                                onChange={handleChange}
                                errors={errors}
                                touched={touched}
                                checkBoxText={status?.label}
                                value={status?.value}
                              />
                            ))}
                          </div>
                          <div className='fv-plugins-message-container text-danger fw-bolder small text-end mt-1'>
                            <ErrorMessage
                              name='status'
                              component='div'
                              className='field-error-message'
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">

                        <div className='col-md-12 col-sm-12 col-xs-12 mt-4'>
                          <FormTinyMce
                            containerClassName='col-md-12'
                            label='Description'
                            name='description'
                            initialValue={
                              !isEmpty(editSelectedData) ? editSelectedData?.description : ''
                            }
                            handleChange={handleChange}
                          />
                        </div>
                      </div>



                      <div className='d-flex justify-content-end px-9'>
                        <button
                          type='submit'
                          disabled={loading}
                          className='btn btn-primary btn-sm ms-3'
                        >
                          Save
                        </button>
                        <button
                          type='button'
                          onClick={handleClose}
                          className='btn btn-secondary btn-sm ms-3'
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default AddSubServiceManager
