import {ParamsModel} from 'src/app/modules/common/Model'
import {SubServiceManagerModel} from '../Model'
import {actionTypes} from './constants'

export const actions = {
  getAllSubServiceManager: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_ALL_SUB_SERVICE_MANAGER_START,
    payload: {params},
  }),

  getAllSubServiceManagerSuccess: (data: SubServiceManagerModel | any) => ({
    type: actionTypes.GET_ALL_SUB_SERVICE_MANAGER_SUCCESS,
    payload: data,
  }),

  getAllSubServiceManagerError: (data: any) => ({
    type: actionTypes.GET_ALL_SUB_SERVICE_MANAGER_FINISH,
    payload: data,
  }),

  enableSubServiceManager: (data: {id: string}[]) => ({
    type: actionTypes.ENABLE_SUB_SERVICE_MANAGER_REQUEST,
    payload: {data},
  }),

  enableSubServiceManagerSuccess: (task: any) => ({
    type: actionTypes.ENABLE_SUB_SERVICE_MANAGER_SUCCESS,
    payload: task,
  }),
  enableSubServiceManagerFinish: () => ({
    type: actionTypes.ENABLE_SUB_SERVICE_MANAGER_FINISH,
  }),

  disableSubServiceManager: (data: {id: string}[]) => ({
    type: actionTypes.DISABLE_SUB_SERVICE_MANAGER_REQUEST,
    payload: {data},
  }),

  disableSubServiceManagerSuccess: (task: any) => ({
    type: actionTypes.DISABLE_SUB_SERVICE_MANAGER_SUCCESS,
    payload: task,
  }),
  disableSubServiceManagerFinish: () => ({
    type: actionTypes.DISABLE_SUB_SERVICE_MANAGER_FINISH,
  }),

  singleDisableSubServiceManager: (data: {id: string}) => ({
    type: actionTypes.SINGLE_DISABLE_SUB_SERVICE_MANAGER_REQUEST,
    payload: {data},
  }),

  singleDisableSubServiceManagerSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_SUB_SERVICE_MANAGER_SUCCESS,
    payload: task,
  }),

  singleDisableSubServiceManagerFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_SUB_SERVICE_MANAGER_FINISH,
  }),

  singleEnableSubServiceManager: (data: {id: string}) => ({
    type: actionTypes.SINGLE_ENABLE_SUB_SERVICE_MANAGER_REQUEST,
    payload: {data},
  }),

  singleEnableSubServiceManagerSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_SUB_SERVICE_MANAGER_SUCCESS,
    payload: task,
  }),

  singleEnableSubServiceManagerFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_SUB_SERVICE_MANAGER_FINISH,
  }),

  deleteSubServiceManager: (data: {id: string}[]) => ({
    type: actionTypes.DELETE_SUB_SERVICE_MANAGER_START,
    payload: {serviceId: data},
  }),

  deleteSubServiceManagerSuccess: (data: any) => ({
    type: actionTypes.DELETE_SUB_SERVICE_MANAGER_SUCCESS,
    payload: data,
  }),

  deleteSubServiceManagerFinish: () => ({
    type: actionTypes.DELETE_SUB_SERVICE_MANAGER_FINISH,
  }),

  addSubServiceManager: (data: SubServiceManagerModel | any) => ({
    type: actionTypes.ADD_SUB_SERVICE_MANAGER_START,
    payload: data,
  }),

  addSubServiceManagerSuccess: (data: SubServiceManagerModel | any) => ({
    type: actionTypes.ADD_SUB_SERVICE_MANAGER_SUCCESS,
    payload: data,
  }),

  addSubServiceManagerFinish: () => ({
    type: actionTypes.ADD_SUB_SERVICE_MANAGER_FINISH,
  }),

  resetSubServiceManager: () => ({
    type: actionTypes.RESET_SUB_SERVICE_MANAGER,
  }),

  updateSubServiceManager: (data: SubServiceManagerModel | any) => ({
    type: actionTypes.UPDATE_SUB_SERVICE_MANAGER_START,
    payload: data,
  }),

  updateSubServiceManagerSuccess: (data: any) => ({
    type: actionTypes.UPDATE_SUB_SERVICE_MANAGER_SUCCESS,
    payload: data,
  }),

  updateSubServiceManagerFinish: () => ({
    type: actionTypes.UPDATE_SUB_SERVICE_MANAGER_FINISH,
  }),
}
