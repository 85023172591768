import Edit2 from '@rsuite/icons/Edit'
import TrashIcon from '@rsuite/icons/Trash'
import { isEmpty } from 'lodash'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { IconButton, Pagination, Tooltip, Whisper } from 'rsuite'
import Table from 'rsuite/Table'
import Tag from 'rsuite/Tag'
import Toggle from 'rsuite/Toggle'
import { ItemType, StateParamsModel } from 'src/app/modules/common/Model'
import DeleteModal from 'src/app/modules/common/components/deleteModal'
import DesignComponent from 'src/app/modules/common/components/designComponent'
import * as subServiceManagerRedux from 'src/app/modules/prime-customer-portal/service/subServiceManger/redux'
import * as serviceManagerRedux from 'src/app/modules/prime-customer-portal/service/serviceManager/redux'
import RSuiteTable from 'src/cms/helpers/components/rsuiteTable'
import AddSubServiceManager from './AddSubServiceManager'

const Cell = Table.Cell

const SubServiceManager = () => {
  const dispatch = useDispatch()
  const [params, setParams] = useState<StateParamsModel>({
    page: 5,
    limit: 10,
  })
  const subServiceManagerData: subServiceManagerRedux.ISubServiceManagerState = useSelector((state: any) => {
    return state?.subServiceManager
  })
  const { data: serviceData, loading: serviceLoading } = useSelector((state: any) => state.serviceManager)

  const serviceDataList = useMemo(() => {
    if (!serviceData?.service || !serviceData?.service?.length) return []
    return serviceData?.service
  }, [serviceData])

  const {
    loading,
    singleDisableSuccess,
    singleEnableSuccess,
    deleteSuccess,
    sortSubServiceManagerData,
    success,
    enableSuccess,
    disableSuccess,

  } = subServiceManagerData

  const [alertOpen, setAlertOpen] = useState(false)
  const handleAlertOpen = () => setAlertOpen(true)
  const [checkedValues, setCheckedValues] = useState<Array<string>>([])
  const [open, setOpen] = useState(false)
  const [actionType, setActionType] = useState('Add')
  const [editSelectedData, setEditCheckedData] = useState<any>()
  const [openSortModal, setOpenSortModal] = useState(false)

  const data =
    subServiceManagerData?.data?.subService?.map((item: any, i: number) => ({
      ...item,
      sn: (params?.page - 1) * params?.limit + (i + 1),
      customerId: item?.customerId,
      firstName: item?.firstName,
      email: item?.email,
      mobileNumber: item?.mobileNumber,
      serviceName: serviceDataList?.find((servItem: any) => servItem?.id === item?.service_id)?.title
    })) ?? []

  const handleChangeLimit = (dataKey: number) => {
    setParams({
      ...params,
      limit: dataKey,
    })
  }

  const handleDelete = () => {
    let subServiceId = checkedValues?.map((value) => ({ id: value }))
    dispatch(subServiceManagerRedux?.actions?.deleteSubServiceManager(subServiceId))
    setAlertOpen(false)
  }

  const handleGetAllData = () => {
    setParams({ ...params, limit: subServiceManagerData?.data?.meta?.total ?? 0 })
    dispatch(subServiceManagerRedux.actions.getAllSubServiceManager(params))
  }

  const handleToggleAction = (data: { [key: string]: string }) => {
    data?.status
      ? dispatch(subServiceManagerRedux.actions.singleDisableSubServiceManager({ id: data.id }))
      : dispatch(subServiceManagerRedux.actions.singleEnableSubServiceManager({ id: data.id }))
  }

  const enableSubServiceManagerData = () => {
    if (!isEmpty(checkedValues)) {
      const formData = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(subServiceManagerRedux.actions.enableSubServiceManager(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  const disableSubServiceManagerData = () => {
    if (!isEmpty(checkedValues)) {
      const formData = checkedValues.map((d: string) => ({
        id: d,
      }))
      dispatch(subServiceManagerRedux.actions.disableSubServiceManager(formData))
    } else {
      toast.error('No data Selected')
    }
  }

  useEffect(() => {
    dispatch(serviceManagerRedux.actions.getAllServiceManager({ page: 1, limit: 1000 }))
  }, [])


  useEffect(() => {
    dispatch(subServiceManagerRedux.actions.getAllSubServiceManager(params))
  }, [params])

  const handleChecked = (values: Array<string>) => {
    setCheckedValues(values)
  }

  useEffect(() => {
    if (singleEnableSuccess) {
      toast.success('Sub Service Manager enabled successfully')
    }
    if (singleDisableSuccess) {
      toast.success('Sub Service Manager disabled successfully')
    }
    if (deleteSuccess) {
      toast.success('Sub Service Manager deleted successfully')
      dispatch(subServiceManagerRedux?.actions.getAllSubServiceManager(params))
    }
    if (enableSuccess) {
      toast.success('Sub Service Manager enabled successfully')
    }
    if (disableSuccess) {
      toast.success('Sub Service Manager disabled successfully')
    }
    handleChecked([])
  }, [singleDisableSuccess, singleEnableSuccess, deleteSuccess, enableSuccess, disableSuccess])


  const ActionCell = ({ rowData, dataKey, ...props }: any) => {
    return (
      <Cell {...props} className='link-group'>
        <Whisper placement='top' trigger='hover' speaker={<Tooltip>Edit</Tooltip>}>
          <IconButton
            appearance='subtle'
            icon={<Edit2 />}
            onClick={() => {
              setEditCheckedData(rowData)
              setActionType('Edit')
              setOpen(true)
            }}
          />
        </Whisper>

        <Whisper placement='top' trigger='hover' speaker={<Tooltip>Delete</Tooltip>}>
          <IconButton
            appearance='subtle'
            onClick={() => {
              handleAlertOpen()
              setCheckedValues([rowData.id])
            }}
            icon={<TrashIcon />}
          />
        </Whisper>

        <Whisper placement='top' trigger='hover' speaker={<Tooltip>Status</Tooltip>}>
          <Toggle
            size='sm'
            disabled={false}
            checked={rowData.status}
            loading={loading}
            onClick={() => handleToggleAction(rowData)}
          />
        </Whisper>
      </Cell>
    )
  }

  //table columns
  const columns = [
    {
      label: 'S.N.',
      dataKey: 'sn',
      width: 100,
      cell: <Cell dataKey='sn' />,
    },
    {
      label: 'Service Name',
      dataKey: 'serviceName',
      cell: <Cell dataKey='serviceName' />,
      sortable: true,
      width: 230,
    },

    {
      label: 'Title',
      dataKey: 'title',
      cell: <Cell dataKey='title' />,
      sortable: true,
      width: 250,
    },

    // {
    //   label: 'Image',
    //   dataKey: 'image',
    //   cell: <Cell dataKey='image' />,
    //   sortable: true,
    //   width: 250,
    // },

    // {
    //   label: 'First Button',
    //   dataKey: 'first_button',
    //   cell: <Cell dataKey='first_button' />,
    //   sortable: true,
    //   width: 250,
    // },

    // {
    //   label: 'Second Button',
    //   dataKey: 'second_button',
    //   width: 230,
    //   cell: <Cell dataKey='second_button' />,
    //   sortable: true,
    // },

    {
      label: 'Status',
      dataKey: 'status',
      width: 85,
      cell: (
        <Cell>
          {(rowData: any) =>
            rowData?.status ? (
              <Tag color='green' size='sm'>
                Active
              </Tag>
            ) : (
              <Tag color='red' size='sm'>
                Inactive
              </Tag>
            )
          }
        </Cell>
      ),
    },

    {
      label: 'Action',
      width: 150,
      align: 'center',
      cell: <ActionCell dataKey='id' />,
    },
  ]

  const handleRefresh = () => {
    dispatch(subServiceManagerRedux.actions.getAllSubServiceManager(params))
  }

  const handleAddModal = () => {
    setEditCheckedData({})
    setOpen(true)
    setActionType('Add')
  }

  return (
    <div>
      <div className='shadow p-3 bg-white rounded'>
        <DesignComponent
          moduleName='Sub Service Manager'
          params={params}
          setParams={setParams}
          addShow={true}
          deleteShow={true}
          filterData={false}
          handleRefresh={handleRefresh}
          handleAlertOpen={handleAlertOpen}
          importShow={false}
          exportShow={false}
          toggleMultipleShow={true}
          data={data}
          sortButtonName='Sort'
          sortShow={false}
          handleGetAllData={handleGetAllData}
          loading={loading || serviceLoading}
          handleAddModal={handleAddModal}
          openSortModal={openSortModal}
          setOpenSortModal={setOpenSortModal}
          // handleSubmitSort={handleSubmitSort}
          enableMultiple={enableSubServiceManagerData}
          disableMultiple={disableSubServiceManagerData}
        />
        <div className='datatable'>
          <RSuiteTable
            showCheckbox={true}
            columns={columns}
            data={data}
            showLoading={subServiceManagerData?.loading || serviceLoading}
            onChecked={handleChecked}
          />
          <Pagination
            prev
            next
            first
            last
            ellipsis
            boundaryLinks
            maxButtons={5}
            size='sm'
            layout={['total', '-', 'limit', '|', 'pager', 'skip']}
            total={subServiceManagerData?.data?.meta?.total || 0}
            limitOptions={[10, 20, 50, 100]}
            limit={params?.limit}
            activePage={params?.page}
            onChangePage={(value) => setParams({ ...params, page: value })}
            onChangeLimit={handleChangeLimit}
          />
        </div>

        {open && (
          <AddSubServiceManager
            open={open}
            actionType={actionType}
            handleClose={() => {
              setOpen(false)
            }}
            editSelectedData={editSelectedData}
          />
        )}

        {alertOpen &&
          (!isEmpty(checkedValues) ? (
            <DeleteModal
              handleClick={() => handleDelete()}
              isOpen={alertOpen}
              handleClose={() => {
                setAlertOpen(false)
              }}
            />
          ) : (
            toast.error('No data selected') && setAlertOpen(false)
          ))}
      </div>
    </div>
  )
}

export default SubServiceManager
