import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {BannerManagerModel} from '../Model'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const BANNER_MANAGER_DATA = `${API_URL}/banner`

export const service = {
  getAllBannerManager: (params: ParamsModel) => {
    return axios.get(BANNER_MANAGER_DATA, {params})
  },

  enableBannerManager: (data: Array<string>) => {
    const bannerManager = {
      bannerId: data,
    }
    return axios.patch(`${BANNER_MANAGER_DATA}/enable`, bannerManager)
  },

  disableBannerManager: (data: Array<string>) => {
    const bannerManager = {
      bannerId: data,
    }
    return axios.patch(`${BANNER_MANAGER_DATA}/disable`, bannerManager)
  },

  singleEnableBannerManager: (data: Array<string>) => {
    const selectedBannerManager = {
      bannerId: [data],
    }
    return axios.patch(`${BANNER_MANAGER_DATA}/enable`, selectedBannerManager)
  },

  singleDisableBannerManager: (data: Array<string>) => {
    const selectedBannerManager = {
      bannerId: [data],
    }
    return axios.patch(`${BANNER_MANAGER_DATA}/disable`, selectedBannerManager)
  },

  deleteBannerManager: (data: {id: string}) => {
    return axios.delete(BANNER_MANAGER_DATA, {data})
  },

  addBannerManager: (data: any) => {
    return axios.post(BANNER_MANAGER_DATA, data)
  },

  updateBannerManager: (data: BannerManagerModel, id: string) => {
    console.log(id, 'axios')
    return axios.patch(`${BANNER_MANAGER_DATA}/${id}`, data)
  },
}
