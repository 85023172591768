import {ParamsModel} from 'src/app/modules/common/Model'
import {CardManagerModel, SortCardManagerModel} from '../Model'
import {actionTypes} from './constants'

export const actions = {
  getAllCardManager: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_ALL_CARD_MANAGER_START,
    payload: {params},
  }),

  getAllCardManagerSuccess: (data: CardManagerModel | any) => ({
    type: actionTypes.GET_ALL_CARD_MANAGER_SUCCESS,
    payload: data,
  }),

  getAllCardManagerError: (data: any) => ({
    type: actionTypes.GET_ALL_CARD_MANAGER_FINISH,
    payload: data,
  }),

  //Enable CardManager
  enableCardManager: (data: {id: string}[]) => ({
    type: actionTypes.ENABLE_CARD_MANAGER_REQUEST,
    payload: {data},
  }),

  enableCardManagerSuccess: (task: any) => ({
    type: actionTypes.ENABLE_CARD_MANAGER_SUCCESS,
    payload: task,
  }),
  enableCardManagerFinish: () => ({
    type: actionTypes.ENABLE_CARD_MANAGER_FINISH,
  }),

  //Disable CardManager
  disableCardManager: (data: {id: string}[]) => ({
    type: actionTypes.DISABLE_CARD_MANAGER_REQUEST,
    payload: {data},
  }),

  disableCardManagerSuccess: (task: any) => ({
    type: actionTypes.DISABLE_CARD_MANAGER_SUCCESS,
    payload: task,
  }),
  disableCardManagerFinish: () => ({
    type: actionTypes.DISABLE_CARD_MANAGER_FINISH,
  }),

  singleDisableCardManager: (data: {id: string}) => ({
    type: actionTypes.SINGLE_DISABLE_CARD_MANAGER_REQUEST,
    payload: {data},
  }),

  singleDisableCardManagerSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_CARD_MANAGER_SUCCESS,
    payload: task,
  }),

  singleDisableCardManagerFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_CARD_MANAGER_FINISH,
  }),

  singleEnableCardManager: (data: {id: string}) => ({
    type: actionTypes.SINGLE_ENABLE_CARD_MANAGER_REQUEST,
    payload: {data},
  }),

  singleEnableCardManagerSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_CARD_MANAGER_SUCCESS,
    payload: task,
  }),

  singleEnableCardManagerFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_CARD_MANAGER_FINISH,
  }),

  deleteCardManager: (data: {id: string}[]) => ({
    type: actionTypes.DELETE_CARD_MANAGER_START,
    payload: {cardId: data},
  }),

  deleteCardManagerSuccess: (data: any) => ({
    type: actionTypes.DELETE_CARD_MANAGER_SUCCESS,
    payload: data,
  }),

  deleteCardManagerFinish: () => ({
    type: actionTypes.DELETE_CARD_MANAGER_FINISH,
  }),

  addCardManager: (data: CardManagerModel | any) => ({
    type: actionTypes.ADD_CARD_MANAGER_START,
    payload: data,
  }),

  addCardManagerSuccess: (data: CardManagerModel | any) => ({
    type: actionTypes.ADD_CARD_MANAGER_SUCCESS,
    payload: data,
  }),

  addCardManagerFinish: () => ({
    type: actionTypes.ADD_CARD_MANAGER_FINISH,
  }),

  resetCardManager: () => ({
    type: actionTypes.RESET_CARD_MANAGER,
  }),

  updateCardManager: (data: CardManagerModel | any, id: string) => ({
    type: actionTypes.UPDATE_CARD_MANAGER_START,
    payload: data,
    id,
  }),

  updateCardManagerSuccess: (data: any) => ({
    type: actionTypes.UPDATE_CARD_MANAGER_SUCCESS,
    payload: data,
  }),

  updateCardManagerFinish: () => ({
    type: actionTypes.UPDATE_CARD_MANAGER_FINISH,
  }),

  sortCardManager: (data: SortCardManagerModel) => ({
    type: actionTypes.SORT_CARD_MANAGER_START,
    payload: data,
  }),

  sortCardManagerSuccess: (data: Array<CardManagerModel>) => ({
    type: actionTypes.SORT_CARD_MANAGER_SUCCESS,
    payload: data,
  }),

  sortCardManagerFinish: () => ({
    type: actionTypes.SORT_CARD_MANAGER_FINISH,
  }),

  sortCardManagerReset: () => ({
    type: actionTypes.SORT_CARD_MANAGER_RESET,
  }),
}
