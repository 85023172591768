import {reducer} from './../../app/modules/profileComponent/redux/reducer'
import {combineReducers} from 'redux'
import {all} from 'redux-saga/effects'

import * as auth from 'src/app/modules/auth'
import * as cms from 'src/app/modules/cms'
import * as banner from 'src/app/modules/cms/components/banner/redux'
import * as rolePermission from 'src/app/modules/common'
import {globalErrorReducer} from 'src/app/modules/errors/redux/reducer'
import * as network from 'src/app/modules/network'
import * as profile from 'src/app/modules/profileComponent'
import * as siteModule from 'src/app/modules/siteSettings'
import * as userManagement from 'src/app/modules/userMgmt'
import * as departments from 'src/app/modules/userMgmt/components/departments'
import * as userRole from 'src/app/modules/userMgmt/components/userRoles'

import * as faqIconType from 'src/app/modules/cms/components/faqCategory/faqIconType/redux'
import * as faqCategory from 'src/app/modules/cms/components/faqCategory/redux'
import * as faqManager from 'src/app/modules/cms/components/faqManager/redux'
import * as roadBlockPopup from 'src/app/modules/cms/components/roadblockPopup/redux'
import * as textPopup from 'src/app/modules/cms/components/textPopup/redux'
import * as mediaManager from 'src/app/modules/common/components/fileManager'

import * as emailTemplate from 'src/app/modules/cms/components/emailTemplate/redux'
import * as formsDownload from 'src/app/modules/formsAndDownload'
import * as municipality from 'src/app/modules/reports/components/municipality/components/redux'

import * as dashboard from 'src/app/pages/dashboard/GoogleAnalyticsRedux'

import * as enquiryManager from 'src/app/modules/inquiry/components/enquiryManager/index'
import * as enquiryType from 'src/app/modules/inquiry/components/enquiryType/index'

//account opening

import * as accountOpenList from 'src/app/modules/onlineAccount/accountOpen/redux'
import * as checkerList from 'src/app/modules/onlineAccount/checkerList/redux'
import * as checkerRepairList from 'src/app/modules/onlineAccount/checkerRepairList/redux'

import * as makerList from 'src/app/modules/onlineAccount/makerList/redux'
import * as newMakerList from 'src/app/modules/onlineAccount/newMakerList/redux'
import * as preliminaryUnverified from 'src/app/modules/onlineAccount/preliminaryUnverified/redux'
import * as preliminaryVerified from 'src/app/modules/onlineAccount/preliminaryVerified/redux'

import * as makerRepairList from 'src/app/modules/onlineAccount/makerRepairList/redux'
import * as pendingAccountList from 'src/app/modules/onlineAccount/pendingAccount/redux'
import * as rejectedList from 'src/app/modules/onlineAccount/rejectedList/redux'

// OnlineOnPrim
import * as onlineOnPrim from 'src/app/modules/onlineAccount/onlineOnPrim/redux'

//product manager
import * as productManager from 'src/app/modules/products/productManager/redux'

// FD Product Manager
import * as fdProductManager from 'src/app/modules/fdProductManagement/fdProductManager/redux'
import * as fdRequest from 'src/app/modules/fdRequest/fdRequestList/redux'
import * as fdRequestUnverified from 'src/app/modules/fdRequest/fdRequestUnverified/redux'
import * as fdRejectedList from 'src/app/modules/fdRequest/fdReject/redux'
import * as fdApprovedList from 'src/app/modules/fdRequest/fdApproved/redux'
// tenure
import * as tenureManager from 'src/app/modules/fdProductManagement/tenure/redux'
import * as termAndConditionManager from 'src/app/modules/TermAndCondition/termAndConditionManager/redux'

// banner manager
import * as bannerManager from 'src/app/modules/prime-customer-portal/banner/bannerManager/redux'
// service
import * as serviceManager from 'src/app/modules/prime-customer-portal/service/serviceManager/redux'
import * as subServiceManager from 'src/app/modules/prime-customer-portal/service/subServiceManger/redux'
// card manager
import * as cardManager from 'src/app/modules/prime-customer-portal/card/cardManager/redux'

export const rootReducer = combineReducers({
  enquiryManager: enquiryManager.reducer,
  enquiryType: enquiryType.reducer,

  auth: auth.reducer,
  globalError: globalErrorReducer,
  smtp: siteModule.smtp.reducer,
  key: siteModule.key.reducer,
  groupSetting: siteModule.groupSetting.reducer,
  settingType: siteModule.settings.reducer,
  userManagement: userManagement.reducer,
  module: siteModule.module.reducer,
  permission: rolePermission.reducer,
  place: rolePermission.placeReducer,
  categoryType: rolePermission.categoryTypeReducer,
  customerTestimonialMediaType: rolePermission.mediaTypeReducer,
  role: userManagement.roles.reducer,
  userRoles: userRole.reducer,
  departments: departments.reducer,
  profile: profile.reducer,
  menuManager: cms.menu.reducer,
  content: cms.content.reducer,

  //Branch
  branch: network.branch.reducer,

  //CMS
  banner: banner.reducer,

  emailTemplate: emailTemplate.reducer,
  textPopup: textPopup.reducer,
  roadBlockPopup: roadBlockPopup.reducer,
  faqCategory: faqCategory.reducer,
  faqManager: faqManager.reducer,

  mediaManager: mediaManager.reducer,
  faqIconType: faqIconType.reducer,

  //Loan
  municipality: municipality.reducer,

  // file types
  fileTypes: onlineOnPrim.fileTypeReducer,

  //Forms and Download
  formsAndDownload: formsDownload.formAndDownload.reducer,
  formsAndDownloadCategory: formsDownload.formAndDownload.reducer1,

  //Contact Person
  contactPerson: siteModule.contactPerson.reducer,

  //Homepage Setting
  homepageSetting: siteModule.homepageSetting.reducer,

  //Dashboard
  devices: dashboard.reducer,
  country: dashboard.reducer1,
  unique: dashboard.reducer2,

  //account opening
  preliminaryVerified: preliminaryVerified.reducer,
  preliminaryUnverified: preliminaryUnverified.reducer,
  checkerList: checkerList.reducer,
  checkerRepairList: checkerRepairList.reducer,
  makerList: makerList.reducer,
  newMakerList: newMakerList.reducer,
  pendingAccountList: pendingAccountList.reducer,
  rejectedList: rejectedList.reducer,
  makerRepairList: makerRepairList.reducer,
  accountOpenList: accountOpenList.reducer,

  onlineOnPrim: onlineOnPrim.reducer,

  //product manager
  productManager: productManager.reducer,

  //fd product manager
  fdProductManager: fdProductManager.reducer,
  tenureManager: tenureManager.reducer,
  fdRequestUnverified: fdRequestUnverified.reducer,
  fdRequest: fdRequest.reducer,
  fdRejectedList: fdRejectedList.reducer,
  fdApprovedList: fdApprovedList.reducer,
  termAndConditionManager: termAndConditionManager.reducer,

  // banner manager
  bannerManager: bannerManager.reducer,

  // service
  serviceManager: serviceManager.reducer,
  subServiceManager: subServiceManager.reducer,

  // card manager
  cardManager: cardManager.reducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([
    enquiryType.saga(),
    enquiryManager.saga(),

    auth.saga(),
    siteModule.smtp.saga(),
    siteModule.key.saga(),
    siteModule.settings.saga(),
    siteModule.groupSetting.saga(),
    siteModule.module.saga(),
    userRole.saga(),
    departments.saga(),
    rolePermission.saga(),
    userManagement.saga(),
    userManagement.roles.saga(),
    profile.saga(),
    cms.menu.saga(),
    cms.content.saga(),

    //Network
    network.branch.saga(),
    banner.saga(),

    textPopup.saga(),
    roadBlockPopup.saga(),

    mediaManager.saga(),
    faqCategory.saga(),
    faqManager.saga(),
    faqIconType.saga(),

    //Loan
    emailTemplate.saga(),
    municipality.saga(),
    formsDownload.formAndDownload.saga(),

    //Contact Person
    siteModule.contactPerson.saga(),

    //Homepage Setting
    siteModule.homepageSetting.saga(),

    //dashboard
    dashboard.saga(),

    //account opening
    preliminaryVerified.saga(),
    preliminaryUnverified.saga(),
    checkerList.saga(),
    checkerRepairList.saga(),
    makerList.saga(),
    newMakerList.saga(),
    pendingAccountList.saga(),
    rejectedList.saga(),
    makerRepairList.saga(),
    accountOpenList.saga(),
    onlineOnPrim.saga(),

    //product manager
    productManager.saga(),
    //fd product manager
    fdProductManager.saga(),
    fdRequest.saga(),
    fdRejectedList.saga(),
    fdApprovedList.saga(),
    tenureManager.saga(),
    fdRequestUnverified.saga(),
    termAndConditionManager.saga(),

    // banner manager
    bannerManager.saga(),
    serviceManager.saga(),
    subServiceManager.saga(),
    // card manager
    cardManager.saga(),
  ])
}
