import {ParamsModel} from 'src/app/modules/common/Model'
import {ServiceManagerModel} from '../Model'
import {actionTypes} from './constants'

export const actions = {
  getAllServiceManager: (params: ParamsModel = {page: 1, limit: 10}) => ({
    type: actionTypes.GET_ALL_SERVICE_MANAGER_START,
    payload: {params},
  }),

  getAllServiceManagerSuccess: (data: ServiceManagerModel | any) => ({
    type: actionTypes.GET_ALL_SERVICE_MANAGER_SUCCESS,
    payload: data,
  }),

  getAllServiceManagerError: (data: any) => ({
    type: actionTypes.GET_ALL_SERVICE_MANAGER_FINISH,
    payload: data,
  }),

  enableServiceManager: (data: {id: string}[]) => ({
    type: actionTypes.ENABLE_SERVICE_MANAGER_REQUEST,
    payload: {data},
  }),

  enableServiceManagerSuccess: (task: any) => ({
    type: actionTypes.ENABLE_SERVICE_MANAGER_SUCCESS,
    payload: task,
  }),
  enableServiceManagerFinish: () => ({
    type: actionTypes.ENABLE_SERVICE_MANAGER_FINISH,
  }),

  disableServiceManager: (data: {id: string}[]) => ({
    type: actionTypes.DISABLE_SERVICE_MANAGER_REQUEST,
    payload: {data},
  }),

  disableServiceManagerSuccess: (task: any) => ({
    type: actionTypes.DISABLE_SERVICE_MANAGER_SUCCESS,
    payload: task,
  }),
  disableServiceManagerFinish: () => ({
    type: actionTypes.DISABLE_SERVICE_MANAGER_FINISH,
  }),

  singleDisableServiceManager: (data: {id: string}) => ({
    type: actionTypes.SINGLE_DISABLE_SERVICE_MANAGER_REQUEST,
    payload: {data},
  }),

  singleDisableServiceManagerSuccess: (task: any) => ({
    type: actionTypes.SINGLE_DISABLE_SERVICE_MANAGER_SUCCESS,
    payload: task,
  }),

  singleDisableServiceManagerFinish: () => ({
    type: actionTypes.SINGLE_DISABLE_SERVICE_MANAGER_FINISH,
  }),

  singleEnableServiceManager: (data: {id: string}) => ({
    type: actionTypes.SINGLE_ENABLE_SERVICE_MANAGER_REQUEST,
    payload: {data},
  }),

  singleEnableServiceManagerSuccess: (task: any) => ({
    type: actionTypes.SINGLE_ENABLE_SERVICE_MANAGER_SUCCESS,
    payload: task,
  }),

  singleEnableServiceManagerFinish: () => ({
    type: actionTypes.SINGLE_ENABLE_SERVICE_MANAGER_FINISH,
  }),

  deleteServiceManager: (data: {id: string}[]) => ({
    type: actionTypes.DELETE_SERVICE_MANAGER_START,
    payload: {serviceId: data},
  }),

  deleteServiceManagerSuccess: (data: any) => ({
    type: actionTypes.DELETE_SERVICE_MANAGER_SUCCESS,
    payload: data,
  }),

  deleteServiceManagerFinish: () => ({
    type: actionTypes.DELETE_SERVICE_MANAGER_FINISH,
  }),

  addServiceManager: (data: ServiceManagerModel | any) => ({
    type: actionTypes.ADD_SERVICE_MANAGER_START,
    payload: data,
  }),

  addServiceManagerSuccess: (data: ServiceManagerModel | any) => ({
    type: actionTypes.ADD_SERVICE_MANAGER_SUCCESS,
    payload: data,
  }),

  addServiceManagerFinish: () => ({
    type: actionTypes.ADD_SERVICE_MANAGER_FINISH,
  }),

  resetServiceManager: () => ({
    type: actionTypes.RESET_SERVICE_MANAGER,
  }),

  updateServiceManager: (data: ServiceManagerModel | any) => ({
    type: actionTypes.UPDATE_SERVICE_MANAGER_START,
    payload: data,
  }),

  updateServiceManagerSuccess: (data: any) => ({
    type: actionTypes.UPDATE_SERVICE_MANAGER_SUCCESS,
    payload: data,
  }),

  updateServiceManagerFinish: () => ({
    type: actionTypes.UPDATE_SERVICE_MANAGER_FINISH,
  }),
}
