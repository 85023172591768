import axios from 'axios'
import {ParamsModel} from 'src/app/modules/common/Model'
import {CardManagerModel, SortCardManagerModel} from '../Model'
// import { CardManagerModel } from '../Model/CardManagerModel'

const API_URL = window.__RUNTIME_CONFIG__.REACT_APP_API_URL

export const CARD_MANAGER_DATA = `${API_URL}/card`

export const service = {
  getAllCardManager: (params: ParamsModel) => {
    return axios.get(CARD_MANAGER_DATA, {params})
  },

  enableCardManager: (data: Array<string>) => {
    const cardManager = {
      cardId: data,
    }
    return axios.patch(`${CARD_MANAGER_DATA}/enable`, cardManager)
  },

  disableCardManager: (data: Array<string>) => {
    const cardManager = {
      cardId: data,
    }
    return axios.patch(`${CARD_MANAGER_DATA}/disable`, cardManager)
  },

  singleEnableCardManager: (data: Array<string>) => {
    const selectedCardManager = {
      cardId: [data],
    }
    return axios.patch(`${CARD_MANAGER_DATA}/enable`, selectedCardManager)
  },

  singleDisableCardManager: (data: Array<string>) => {
    const selectedCardManager = {
      cardId: [data],
    }
    return axios.patch(`${CARD_MANAGER_DATA}/disable`, selectedCardManager)
  },

  deleteCardManager: (data: {id: string}) => {
    return axios.delete(CARD_MANAGER_DATA, {data})
  },

  addCardManager: (data: any) => {
    return axios.post(CARD_MANAGER_DATA, data)
  },

  updateCardManager: (data: CardManagerModel, id: string) => {
    return axios.patch(`${CARD_MANAGER_DATA}/${id}`, data)
  },

  sortCardManager: (body: SortCardManagerModel) => {
    return axios.patch(`${CARD_MANAGER_DATA}/sort`, body)
  },
}
