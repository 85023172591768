export const actionTypes = {
  GET_ALL_BANNER_MANAGER_START: 'GET_ALL_BANNER_MANAGER_START',
  GET_ALL_BANNER_MANAGER_SUCCESS: 'GET_ALL_BANNER_MANAGER_SUCCESS',
  GET_ALL_BANNER_MANAGER_FINISH: 'GET_ALL_BANNER_MANAGER_FINISH',

  ADD_BANNER_MANAGER_START: 'ADD_BANNER_MANAGER_START',
  ADD_BANNER_MANAGER_SUCCESS: 'ADD_BANNER_MANAGER_SUCCESS',
  ADD_BANNER_MANAGER_FINISH: 'ADD_BANNER_MANAGER_FINISH',
  RESET_BANNER_MANAGER: 'RESET_BANNER_MANAGER',

  UPDATE_BANNER_MANAGER_START: 'UPDATE_BANNER_MANAGER_START',
  UPDATE_BANNER_MANAGER_SUCCESS: 'UPDATE_BANNER_MANAGER_SUCCESS',
  UPDATE_BANNER_MANAGER_FINISH: 'UPDATE_BANNER_MANAGER_FINISH',

  DELETE_BANNER_MANAGER_START: 'DELETE_BANNER_MANAGER_START',
  DELETE_BANNER_MANAGER_SUCCESS: 'DELETE_BANNER_MANAGER_SUCCESS',
  DELETE_BANNER_MANAGER_FINISH: 'DELETE_BANNER_MANAGER_FINISH',

  SINGLE_DISABLE_BANNER_MANAGER_REQUEST: 'SINGLE_DISABLE_BANNER_MANAGER_REQUEST',
  SINGLE_DISABLE_BANNER_MANAGER_SUCCESS: 'SINGLE_DISABLE_BANNER_MANAGER_SUCCESS',
  SINGLE_DISABLE_BANNER_MANAGER_FINISH: 'SINGLE_DISABLE_BANNER_MANAGER_FINISH',

  SINGLE_ENABLE_BANNER_MANAGER_REQUEST: 'SINGLE_ENABLE_BANNER_MANAGER_REQUEST',
  SINGLE_ENABLE_BANNER_MANAGER_SUCCESS: 'SINGLE_ENABLE_BANNER_MANAGER_SUCCESS',
  SINGLE_ENABLE_BANNER_MANAGER_FINISH: 'SINGLE_ENABLE_BANNER_MANAGER_FINISH',

  ENABLE_BANNER_MANAGER_REQUEST: 'ENABLE_BANNER_MANAGER_REQUEST',
  ENABLE_BANNER_MANAGER_SUCCESS: 'ENABLE_BANNER_MANAGER_SUCCESS',
  ENABLE_BANNER_MANAGER_FINISH: 'ENABLE_BANNER_MANAGER_FINISH',

  DISABLE_BANNER_MANAGER_REQUEST: 'DISABLE_BANNER_MANAGER_REQUEST',
  DISABLE_BANNER_MANAGER_SUCCESS: 'DISABLE_BANNER_MANAGER_SUCCESS',
  DISABLE_BANNER_MANAGER_FINISH: 'DISABLE_BANNER_MANAGER_FINISH',
}
